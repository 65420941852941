<template>
  <div class="px-6 py-2 flex flex-col font-semibold flex-grow">
    <header class="py-2 text-lg flex text-text-alternate-1 pr-6">
      <button class="focus:outline-none" @click="$router.go(-1)">
        <LeftArrowIcon class="h-6 fill-current" />
      </button>
      <h2 class="mx-auto">
        {{ isSecurity ? 'Security Question' : 'Change Password' }}
      </h2>
    </header>
    <BPageLoader v-if="isLoading" />
    <main class="my-6 flex-grow flex flex-col text-sm" v-else-if="isSecurity">
      <div class="flex-grow">
        <div class="flex flex-col">
          <label class="text-text-primary font-semibold">Security Question</label>
          <input
            class="
              border
              rounded
              border-border-primary
              my-2
              p-3.5
              text-sm
              font-medium
              focus:outline-none
            "
            disabled
            :value="data.securityquestion.question"
          />
        </div>
        <div class="flex flex-col my-3">
          <label class="text-text-primary font-semibold">Answer</label>
          <input
            class="
              border
              rounded
              border-border-primary
              my-2
              p-3.5
              text-sm
              font-medium
              focus:outline-none
            "
            v-model.trim="answer"
            required
          />
        </div>
      </div>
      <div class="text-center">
        <button
          class="bg-btn-bg-primary text-btn-text-primary text-sm rounded w-full my-8 py-4 font-bold"
          @click="verify"
          :disabled="!answer || verificationResponse.isLoading"
        >
          <BButtonLoader class="h-4 w-4 mx-auto" v-if="verificationResponse.isLoading" />
          <span v-else>Next</span>
        </button>
      </div>
    </main>
    <main class="my-2 flex-grow flex flex-col text-sm" v-else>
      <div class="flex-grow">
        <div class="flex flex-col my-4">
          <label class="text-text-primary font-semibold">Old Password</label>
          <div class="flex items-center border rounded border-border-primary my-2 overflow-hidden">
            <input
              class="p-3.5 text-sm font-medium flex-grow focus:outline-none"
              :type="old.hidden ? 'password' : 'text'"
              v-model="old.password"
              placeholder="password"
              required
            />
            <div class="cursor-pointer px-3.5" @click="old.hidden = !old.hidden">
              <PasswordHiddenIcon v-if="old.hidden" />
              <PasswordShownIcon v-else />
            </div>
          </div>
        </div>
        <div class="flex flex-col my-4">
          <label class="text-text-primary font-semibold">New Password</label>
          <div class="flex items-center border rounded border-border-primary my-2 overflow-hidden">
            <input
              class="p-3.5 text-sm font-medium flex-grow focus:outline-none"
              :type="new1.hidden ? 'password' : 'text'"
              v-model="new1.password"
              placeholder="password"
              required
            />
            <div class="cursor-pointer px-3.5" @click="new1.hidden = !new1.hidden">
              <PasswordHiddenIcon v-if="new1.hidden" />
              <PasswordShownIcon v-else />
            </div>
          </div>
          <ul
            class="
              grid grid-cols-2
              py-2
              gap-1
              px-4
              text-2xs
              xs:text-xs
              border
              rounded
              border-border-primary
              text-text-primary
              list-inside
            "
          >
            <li :class="{ right: hasLowercase }">One lowercase character</li>
            <li :class="{ right: hasUppercase }">One uppercase character</li>
            <li :class="{ right: hasSpecialChar }">One special character</li>
            <li :class="{ right: hasNumber }">One number</li>
          </ul>
        </div>
        <div class="flex flex-col my-4">
          <label class="text-text-primary font-semibold">New Password</label>
          <div class="flex items-center border rounded border-border-primary my-2 overflow-hidden">
            <input
              class="p-3.5 text-sm font-medium flex-grow focus:outline-none"
              :type="new2.hidden ? 'password' : 'text'"
              v-model="new2.password"
              placeholder="password"
              required
            />
            <div class="cursor-pointer px-3.5" @click="new2.hidden = !new2.hidden">
              <PasswordHiddenIcon v-if="new2.hidden" />
              <PasswordShownIcon v-else />
            </div>
          </div>
        </div>
      </div>
      <div class="text-center">
        <button
          class="bg-btn-bg-primary text-btn-text-primary text-sm rounded w-full py-4 font-bold"
          @click="handleSubmit"
          :disabled="!isFilled || passwordResponse.isLoading"
        >
          <BButtonLoader class="h-4 w-4 mx-auto" v-if="passwordResponse.isLoading" />
          <span v-else>Update Password</span>
        </button>
      </div>
    </main>
  </div>
  <BModal :isOpen="passwordResponse.isSuccess" @close="handleDone">
    <div class="text-center flex flex-col items-center pb-6">
      <SuccessIcon class="my-6 mx-auto" />
      <h4 class="text-text-primary font-semibold my-4 text-lg">
        Password was changed successfully
      </h4>
      <button
        class="
          bg-btn-bg-primary
          max-w-sm
          text-btn-text-primary
          rounded
          w-full
          mt-4
          py-4
          font-bold
          text-sm
          focus:outline-none
        "
        @click="handleDone"
      >
        Done
      </button>
    </div>
  </BModal>
</template>

<script>
import { ref, computed, toRefs, watch, reactive } from 'vue';
import BModal from '@/components/ui/BModal';
import { useApi } from '@/cmp-functions/useApi';
import { getUserSecurity, checkSecurity, updatePassword } from '@/services/api';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import { useRouter } from 'vue-router';

export default {
  name: 'UpdatePassword',

  components: { BModal },

  setup() {
    const answer = ref('');
    const store = useStore();
    const authUser = store.state.auth.authData;
    const toast = useToast();
    const isSecurity = ref(true);
    const router = useRouter();
    const auth = reactive({
      old: { password: '', hidden: true },
      new1: { password: '', hidden: true },
      new2: { password: '', hidden: true },
    });

    const [response, fetchUserSecurity] = useApi(getUserSecurity);
    const [verificationResponse, verificationSecurity] = useApi(checkSecurity);
    fetchUserSecurity(authUser.id);
    const [passwordResponse, changePassword] = useApi(updatePassword);

    const handleSubmit = () => {
      const { new1, new2, old } = auth;
      const isCorrect = new1.password === new2.password;
      if (!isCorrect) {
        return toast.error('New passwords do the match');
      }
      const payload = { newPassword: new1.password, oldPassword: old.password };
      changePassword({ userId: authUser.id, payload });
    };

    const verify = () => {
      verificationSecurity({ userId: authUser.id, answer: answer.value });
    };

    watch(verificationResponse, ({ isFailure, isSuccess, error }) => {
      if (isFailure) toast.error(error);
      else if (isSuccess) isSecurity.value = false;
    });

    watch(passwordResponse, ({ isFailure, error }) => {
      if (isFailure) toast.error(error);
    });

    const hasUppercase = computed(() => /[A-Z]/.test(auth.new1.password));
    const hasLowercase = computed(() => /[a-z]/.test(auth.new1.password));
    const hasNumber = computed(() => /[0-9]/.test(auth.new1.password));
    const hasSpecialChar = computed(() => /[^0-9A-Za-z]/.test(auth.new1.password));

    const isFilled = computed(
      () =>
        auth.old.password &&
        auth.new2.password &&
        hasUppercase.value &&
        hasLowercase.value &&
        hasNumber.value &&
        hasSpecialChar.value
    );

    const handleDone = () => {
      router.push({ name: 'Security' });
    };

    return {
      ...toRefs(response),
      ...toRefs(auth),
      verificationResponse,
      passwordResponse,
      isSecurity,
      answer,
      hasUppercase,
      hasLowercase,
      hasNumber,
      hasSpecialChar,
      isFilled,
      handleDone,
      verify,
      handleSubmit,
    };
  },
};
</script>

<style lang="scss" scoped>
li {
  @apply flex items-center font-normal;
  &::before {
    content: '•';
    color: #e3e2e2;
    font-size: 20px;
    @apply self-center mb-1 mr-1;
  }
}

li.right {
  color: #017622;
  &::before {
    color: #017622;
  }
}
</style>
